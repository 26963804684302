<template>
  <div>
    <b-row class="d-flex align-items-center match-height">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="filterData.keyword"
            placeholder="Müşteri Kartı Ara"
            @keyup.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              v-if="filterData.keyword.length"
              variant="warning"
              @click="resetSearch"
            >
              <FeatherIcon icon="XIcon" />
            </b-button>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="filterData.detailSearch"
          switch
          @change="resetFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <template v-if="filterData.detailSearch">
      <hr>
      <b-row>
        <b-col>
          <b-form-group
            label="Kullanıcı"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="filterData.id_com_user"
              :options="users"
              label="name"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.name }}
                </div>
                <div class="text-warning font-small-2">
                  {{ option.department }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ option.brand }} - {{ option.location }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                <div>
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-input-group>
              <b-form-datepicker
                id="sdate"
                v-model="filterData.sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterData.sdate">
                <b-button
                  variant="outline-primary"
                  @click="filterData.sdate = null"
                >
                  <FeatherIcon icon="XCircleIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="sdate"
          >
            <b-input-group>
              <b-form-datepicker
                id="edate"
                v-model="filterData.edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append v-if="filterData.edate">
                <b-button
                  variant="outline-primary"
                  @click="filterData.edate = null"
                >
                  <FeatherIcon icon="XCircleIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="warning"
            @click="searchData"
          >
            <FeatherIcon icon="FilterIcon" />
            Filtrele
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BButton, BFormInput, BInputGroup, BInputGroupAppend, BRow, BCol, BFormCheckbox, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    vSelect,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
    resetSearch: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  methods: {
    resetFilter() {
      if (!this.filterData.detailSearch) {
        this.filterData.id_com_user = null
        this.filterData.sdate = null
        this.filterData.edate = null
      }
    },
  },
}
</script>
